import { OeProject, Region } from "rr-types";

import { STRAPI_URL } from "../env";

export async function fetchRegion(regionId: number): Promise<{
  region: Region;
  oes: OeProject[];
}> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff-region/${regionId}`, {
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as {
      region: Region;
      oes: OeProject[];
    };

    return json;
  } catch {
    return {} as {
      region: Region;
      oes: OeProject[];
    };
  }
}
