import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useTheme,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

interface OverviewTableProps {
  entries: {
    division: string;
    oes: {
      oeName: string;
      score: number;
      chipColor: string;
      textColor: string;
    }[];
  }[];
}
export const OverviewTable: PimoReactComponent<OverviewTableProps> = ({
  entries,
}) => {
  const theme = useTheme();
  return (
    <TableContainer>
      {entries.map((entry) => (
        <Table sx={{ mb: 1 }} size="small" aria-label="a dense table">
          <TableBody>
            <TableRow key={entry.division}>
              <TableCell
                sx={{
                  minWidth: 350,
                  borderColor: theme.palette.secondary.main,
                  fontWeight: 500,
                }}
              >
                {entry.division}
              </TableCell>
              <TableCell
                sx={{
                  borderColor: theme.palette.secondary.main,
                  fontWeight: 500,
                }}
              ></TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            {entry.oes.map((entry) => (
              <TableRow>
                <TableCell
                  sx={{
                    minWidth: 350,
                    borderColor: theme.palette.secondary.main,
                  }}
                >
                  {entry.oeName}
                </TableCell>
                <TableCell sx={{ borderColor: theme.palette.secondary.main }}>
                  <Chip
                    label={entry.score}
                    sx={{
                      background: entry.chipColor,
                      height: 20,
                      minWidth: 60,
                      color: entry.textColor,
                    }}
                  ></Chip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ))}
    </TableContainer>
  );
};
