import {
  App,
  composeInitialState,
  LoginPlugin,
  LoginPluginPartialAppState,
  UniversalAppState,
} from "@pimo/pimo-app-builder";
import {
  DefaultOverlayPartialAppState,
  initialDefaultOverlayState,
} from "@pimo/pimo-components";
import { matchPath } from "react-router-dom";
import { GroupDashboard, OeProject, Profile, Region } from "rr-types";

import { APP_KEY } from "../app-key";
import { RROverlay } from "./components/overlay/rr-overlay";
import {
  APP_ROUTES,
  DASHBOARD_PAGE_SECTIONS,
  PAGE_SECTIONS,
} from "./constants";
import { STRAPI_URL } from "./env";
import { fetchOverlay } from "./helpers/fetch-overlay";
import { DashboardPlugin } from "./pages/dashboard-plugin";
import { HomePlugin } from "./pages/home-plugin";
import { NoDataPlugin } from "./pages/no-data.plugin";
import { PDFDashboardPlugin } from "./pages/pdf-dashboard-plugin";
import { PDFRegionPlugin } from "./pages/pdf-region-plugin";
import { PDFReportPlugin } from "./pages/pdf-reports-plugin";
import { RegionPlugin } from "./pages/region-plugin";
import { ReportsPlugin } from "./pages/reports-plugin";
import { theme } from "./theme";

export type RrAppState = DefaultOverlayPartialAppState &
  LoginPluginPartialAppState<Profile> &
  UniversalAppState & {
    oesForMenu: { name: string; id: number }[];
    regionsForMenu: { name: string; id: number }[];
    currentOeReport?: OeProject;
    currentRegion?: Region;
    dashboard?: GroupDashboard;
    oes?: OeProject[];
  };

// Plugins
const loginPlugin = new LoginPlugin<Profile, RrAppState>(
  APP_ROUTES.home,
  STRAPI_URL,
  "IS Risk Reporting"
);

const homePlugin = new HomePlugin<RrAppState>();
const dashboardPlugin = new DashboardPlugin();
const regionsPlugin = new RegionPlugin();
const reportsPlugin = new ReportsPlugin();
const pdfDashboardPlugin = new PDFDashboardPlugin(dashboardPlugin);
const pdfReportsPlugin = new PDFReportPlugin(reportsPlugin);
const pdfRegionPlugin = new PDFRegionPlugin(regionsPlugin);
// App
const app = App.create<RrAppState>(
  composeInitialState<RrAppState>({
    ...initialDefaultOverlayState,
    ...loginPlugin.getInitialState(),
    isLoading: false,
    isSidebarOpen: true,
    oesForMenu: [],
    regionsForMenu: [],
  }),
  APP_KEY
);
app.setTheme(theme);

// Overlay
const overlayView = app.createOverlayView({ name: "Overlay" });

const overlay = overlayView.addComponent({
  component: RROverlay,
});

overlay.mapState(
  ({ isSidebarOpen, userProfile, oesForMenu, regionsForMenu }) => ({
    hasSearchFunctionality: true,
    inPageNavigationSidebarProps: {
      title: "IS Risk Report",
      entries: window.location.pathname.includes("dashboard")
        ? DASHBOARD_PAGE_SECTIONS.map((entry) => ({
            title: entry,
            id: `${entry}`,
          }))
        : PAGE_SECTIONS.map((entry) => ({ title: entry, id: `${entry}` })),
    },
    menuEntries: [
      ...(userProfile?.isAdmin
        ? [
            {
              title: "Group Report",
              link: APP_ROUTES.dashboard,
              level: 0,
              icon: "clipboard.svg",
            },
          ]
        : []),
      ...(regionsForMenu?.length
        ? [
            {
              title: "Region Reports",
              level: 0,
              items: regionsForMenu?.map((region) => ({
                title: region.name,
                level: 1,
                link: `${APP_ROUTES.regions}/${region.id}`,
                active: !!matchPath(
                  `${APP_ROUTES.regions}/${region.id}`,
                  window.location.pathname
                ),
              })),
              icon: "clipboard.svg",
            },
          ]
        : []),
      {
        icon: "barchart.svg",
        title: "OE Reports",
        level: 0,
        items: oesForMenu?.map((oe) => ({
          title: oe.name,
          level: 1,
          link: `${APP_ROUTES.report}/${oe.id}`,
          active: !!matchPath(
            `${APP_ROUTES.report}/${oe.id}`,
            window.location.pathname
          ),
        })),
      },
    ],
    header: {
      logo: { big: "allianz.svg", small: "allianz-small.svg" },
      username: userProfile?.email,
    },

    isSidebarOpen,
  })
);

overlay.on("overlay:logout", () => {
  loginPlugin.logout();
});

overlay.on("overlay:toggle-sidebar", () => {
  app.setAppState({
    ...app.getAppState(),
    isSidebarOpen: !app.getAppState().isSidebarOpen,
  });
});

overlay.on("overlay:download-report", () => {
  if (window.location.pathname.includes("dashboard")) {
    app.navigate("/dashboard-pdf", { state: { print: true } });
  } else if (window.location.pathname.includes("regions")) {
    app.navigate("/region-pdf", { state: { print: true } });
  } else {
    app.navigate("/oe-pdf", { state: { print: true } });
  }
});

app.registerPlugin(loginPlugin);
app.registerPlugin(homePlugin);
app.registerPlugin(regionsPlugin);
app.registerPlugin(reportsPlugin);
app.registerPlugin(dashboardPlugin);
app.registerPlugin(pdfDashboardPlugin);
app.registerPlugin(pdfReportsPlugin);
app.registerPlugin(pdfRegionPlugin);
app.registerPlugin(new NoDataPlugin<RrAppState>());

pdfRegionPlugin.route?.on("load", () => {
  // check if `history.state` is populated.
  // `{ usr? {} }` is injected via `react-router-dom`.
  if ((window.history.state as { usr?: { print?: boolean } })?.usr?.print) {
    window.onafterprint = () => {
      if (window.location.pathname.includes("region-pdf")) {
        // eslint-disable-next-line
        // @ts-ignore - this is fine, `react-router-dom` supports `-1` to navigate to the previous page.
        app.navigate(-1, { state: null });
      }
    };
    // wait until the main thread is clear.
    // this also includes assets that are being fetched.
    window.requestIdleCallback(() => window.print(), { timeout: 1_000 });
  }
});

pdfDashboardPlugin.route?.on("load", () => {
  // check if `history.state` is populated.
  // `{ usr? {} }` is injected via `react-router-dom`.
  if ((window.history.state as { usr?: { print?: boolean } })?.usr?.print) {
    window.onafterprint = () => {
      if (window.location.pathname.includes("dashboard-pdf")) {
        // eslint-disable-next-line
        // @ts-ignore - this is fine, `react-router-dom` supports `-1` to navigate to the previous page.
        app.navigate(-1, { state: null });
      }
    };
    // wait until the main thread is clear.
    // this also includes assets that are being fetched.
    window.requestIdleCallback(() => window.print(), { timeout: 1_000 });
  }
});

pdfReportsPlugin.route?.on("load", () => {
  // check if `history.state` is populated.
  // `{ usr? {} }` is injected via `react-router-dom`.
  if ((window.history.state as { usr?: { print?: boolean } })?.usr?.print) {
    window.onafterprint = () => {
      if (window.location.pathname.includes("oe-pdf")) {
        // eslint-disable-next-line
        // @ts-ignore - this is fine, `react-router-dom` supports `-1` to navigate to the previous page.
        app.navigate(-1, { state: null });
      }
    };
    // wait until the main thread is clear.
    // this also includes assets that are being fetched.
    window.requestIdleCallback(() => window.print(), { timeout: 1_000 });
  }
});

homePlugin.route?.on("load", async () => {
  const [isLoggedIn, data] = await Promise.all([
    loginPlugin.isLoggedIn(),
    fetchOverlay(),
  ]);
  if (isLoggedIn) {
    // if route is loaded and user is not admin, redirect them to the first OE report
    if (data?.isAdmin) {
      app.navigate(APP_ROUTES.dashboard);
    } else if (data?.regionsForMenu.length) {
      app.navigate(`${APP_ROUTES.regions}/${data?.regionsForMenu[0].id}`);
    } else if (data?.oesForMenu.length) {
      app.navigate(`${APP_ROUTES.report}/${data?.oesForMenu[0].id}`);
    } else {
      app.navigate(APP_ROUTES.noData);
    }
  } else {
    app.setAppState({ ...app.getAppState(), isLoading: false });
    app.navigate(APP_ROUTES.noData);
  }
});

loginPlugin.on("login-status-changed", async (evt) => {
  if (!evt.payload?.isLoggedIn) {
    app.navigate(APP_ROUTES.noData);
  }
  pdfDashboardPlugin.render();
  pdfReportsPlugin.render();
  pdfRegionPlugin.render();
  const data = await fetchOverlay();

  app.setAppState({
    ...app.getAppState(),
    oesForMenu: data?.oesForMenu ?? [],
    regionsForMenu: data?.regionsForMenu ?? [],
    userProfile: {
      ...app.getAppState().userProfile,
      email: app.getAppState().userProfile?.email ?? "",
      isAdmin: data?.isAdmin ?? false,
    },
  });
});

const RenderedApp = app.render();

export default RenderedApp;
